/* eslint-disable */
export default () => {
  self.onmessage = (e) => {
    const { data, subentity, filterBy, formattedDEnd } = e.data;
    console.log('data', e.data);
    const predicate = (() => {
      return (item) =>
        Object.keys(filterBy).map((key) =>
          item[key] === filterBy[key]
        ) &&
        (!item.discontinuedDate || item.discontinuedDate >= formattedDEnd);
    })();

    self.postMessage(data[subentity]?.filter(predicate));
  };
};
