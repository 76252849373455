import Auth from '@aws-amplify/auth';
import axios from 'axios';
import { toggleAlertMessage } from '../action';
import createSignedRequest from './createSignedRequest';
import { getOrganisationUsers } from './organisationActions';

let { apiURL } = require('./apiURL');

const Types = {
  AUTHENTICATION_LOGIN: 'AUTHENTICATION_LOGIN',
  AUTHENTICATION_PROCESSING: 'AUTHENTICATION_PROCESSING',
  AUTHENTICATION_LOGOUT: 'AUTHENTICATION_LOGOUT',
  AUTHENTICATION_VALIDATE_CODE: 'AUTHENTICATION_VALIDATE_CODE',
  AUTHENTICATION_RECOVERY_CODE_CHECK: 'AUTHENTICATION_RECOVERY_CODE_CHECK',
  AUTHENTICATION_SETTING_PASSWORD: 'AUTHENTICATION_SETTING_PASSWORD',
  AUTHENTICATION_SET_PASSWORD_RESULT: 'AUTHENTICATION_SET_PASSWORD_RESULT',
  AUTHENTICATION_LOGIN_COGNITO: 'AUTHENTICATION_LOGIN_COGNITO',
  AUTHENTICATION_USER_CREDENTIALS: 'AUTHENTICATION_USER_CREDENTIALS',
  AUTHENTICATION_NEW_USER_DETAILS: 'AUTHENTICATION_NEW_USER_DETAILS',
  AUTHENTICATION_REGISTRATION_DETAILS: 'AUTHENTICATION_REGISTRATION_DETAILS',
  AUTHENTICATION_ACTIVE_CAMPAIGN_DETAILS: 'AUTHENTICATION_ACTIVE_CAMPAIGN_DETAILS',
  AUTHENTICATION_CLOSE_USER_AGREEMENT_MODAL: 'AUTHENTICATION_CLOSE_USER_AGREEMENT_MODAL',
  UPDATE_LEGAL_AGREEMENT: 'AUTHENTICATION_UPDATE_LEGAL_AGREEMENT',
  AUTHENTICATION_INVITE_NEW_USER: 'AUTHENTICATION_INVITE_NEW_USER',
  LIST_WHITE_LABEL_SETTINGS: 'AUTHENTICATION_LIST_WHITE_LABEL_SETTINGS',
};

export const loggingIn = (saving) => ({
  type: Types.AUTHENTICATION_PROCESSING,
  saving,
});

const loginWithCognito = (username, credentials) => ({
  type: Types.AUTHENTICATION_LOGIN_COGNITO,
  payload: username,
  credentials,
});

const updateLoginStatus = (response, attempt) => ({
  type: Types.AUTHENTICATION_LOGIN,
  payload: response,
  attempt,
});

const loginWithAws = (username) => {
  return (dispatch) => {
    dispatch(loggingIn(true));
    dispatch(loginWithCognito(username));
  };
};

const logoutWithAws = (response) => ({
  type: Types.AUTHENTICATION_LOGOUT,
  payload: response,
});

const logout = (response) => {
  return (dispatch) => {
    dispatch(logoutWithAws(response));
  };
};

const isLoggedIn = () => {
  return (dispatch, getState) => {
    dispatch(loggingIn(true));

    fetch(apiURL + '/authentication/status', {
      credentials: 'include',
      method: 'GET',
      // body: JSON.stringify({username,password}),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'Organisation': getState().currentOrganisation,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('entity data', data);
        dispatch(updateLoginStatus(data, false));
      });
  };
};

const getUserCredentials = () => {
  return async (dispatch) => {
    const userCredentials = await Auth.currentUserCredentials();

    dispatch(currentUserCredentials(userCredentials));
  };
};

const currentUserCredentials = (userCredentials) => ({
  type: Types.AUTHENTICATION_USER_CREDENTIALS,
  payload: userCredentials,
});

const newUserSubscription = (user, organisation) => ({
  type: Types.AUTHENTICATION_REGISTRATION_DETAILS,
  payload: { user, organisation },
});

const newUserDetails = (response) => ({
  type: Types.AUTHENTICATION_NEW_USER_DETAILS,
  payload: response,
});

const newUser = (user, organisation) => {
  return async (dispatch, getState) => {
    console.log('Org Saved', organisation);
    console.log('User Saved', user);

    try {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + '/payment/stripe/userRegistration',
          JSON.stringify({ user, organisation })
        )
      );

      console.log('newUser', response.data);
      dispatch(newUserDetails(response.data));
    } catch (e) {
      console.log('newUser Error:', e);
    }
  };
};

const createACContact = (user) => {
  return async () => {
    fetch(apiURL + `/registration/createACContact`, {
      method: 'POST',
      body: JSON.stringify({ user }),
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => console.log('createACContact', data));
  };
};

const inviteNewUser = (user) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { whiteLabelId } = state.whiteLabelAdmin;

    try {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + '/my/organisation/inviteNewUser',
          JSON.stringify({ user, whiteLabelId }),
          {
            Organisation: String(state.currentOrganisation),
          }
        )
      );

      const { message, success } = response.data || {};

      dispatch(toggleAlertMessage(true, message, success ? 'success' : 'error'));
      dispatch(getOrganisationUsers());
    } catch (e) {
      console.log('inviteNewUser Error:', e);
      dispatch(toggleAlertMessage(true, e.message, 'error'));
    }
  };
};

const updateLegalAgreements = (isOrg) => ({
  type: Types.UPDATE_LEGAL_AGREEMENT,
  payload: isOrg,
});

const confirmUserAgreement = (incOrg) => {
  return async (dispatch, getState) => {
    const state = getState();
    const isOrg = incOrg;

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + '/my/organisation/confirmUserAgreement', JSON.stringify({ isOrg }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      dispatch(updateLegalAgreements(isOrg));

      console.log('confirmUserAgreement Response', response.data);
    } catch (e) {
      console.log('confirmUserAgreement Error:', e);
    }
  };
};

const listWLSettings = (response) => ({
  type: Types.LIST_WHITE_LABEL_SETTINGS,
  response,
});

const getWLSettings = () => {
  let wlDomain;
  if (['localhost'].includes(window.location.hostname)) {
    const version = apiURL.split('/')[3];
    apiURL = process.env.REACT_APP_LOCAL_BACK_TEST ? 'http://localhost:3003' : apiURL;
    const v = version === 'v1' ? '' : version === 'stage2' ? 'staging2.' : version === 'stage' ? 'staging.' : 'dev.';
    wlDomain = `${v}app.compareyourfootprint.com`;
  } else {
    wlDomain = window.location.hostname;
  }

  return async (dispatch) => {
    const response = await axios(
      `https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/whiteLabelSettings/${wlDomain}.json`
    )
      .then((res) => res.data)
      .catch((err) => {
        console.log('getWLSettings Error', err);
        return {};
      });

    dispatch(listWLSettings(response));
  };
};

const sendEmailToSalesTeam = (org, user) => {
  return async () => {
    fetch(apiURL + `/registration/sendEmailToSalesTeam`, {
      method: 'POST',
      body: JSON.stringify({ org, user }),
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('sendEmailToSalesTeam', data);
      })
      .catch((err) => console.log('sendEmailToSalesTeam', err));
  };
};

const Actions = {
  isLoggedIn,
  loginWithAws,
  getUserCredentials,
  logout,
  newUser,
  newUserSubscription,
  loggingIn,
  createACContact,
  inviteNewUser,
  confirmUserAgreement,
  getWLSettings,
  sendEmailToSalesTeam,
};

export { Actions, Types };
